import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

const StudentDetail = () => {
  const { id } = useParams(); // Get student ID from the URL
  const navigate = useNavigate();
  const [student, setStudent] = useState({});
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState("");

  // ✅ Convert MM/DD/YYYY to YYYY-MM-DD for <input type="date">
  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const [month, day, year] = dateString.split("-");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  // ✅ Convert YYYY-MM-DD back to MM/DD/YYYY for API submission
  const formatDateForAPI = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${month}-${day}-${year}`;
  };

  useEffect(() => {
    const fetchStudent = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No authentication token found.");
        return;
      }

      const decoded = JSON.parse(atob(token.split(".")[1]));
      setGroups(decoded["cognito:groups"]);

      try {
        const response = await fetch(
          `https://5l7ywi8ha8.execute-api.us-east-1.amazonaws.com/students/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch student data");
        }

        const data = await response.json();
        console.log("Fetched student:", data);

        // ✅ Convert date fields before setting state
        setStudent({
          ...data,
          date_of_birth: formatDateForInput(data.date_of_birth),
          date_last_promoted: formatDateForInput(data.date_last_promoted),
        });

      } catch (error) {
        console.error("Error fetching student:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudent();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudent({
      ...student,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    const formattedStudent = {
      ...student,
      date_of_birth: formatDateForAPI(student.date_of_birth),
      date_last_promoted: formatDateForAPI(student.date_last_promoted),
      active: true
    };

    try {
      console.log(token)
      console.log("BODY OF POST")
      console.log(JSON.stringify(formattedStudent))
      const response = await fetch(
        `https://5l7ywi8ha8.execute-api.us-east-1.amazonaws.com/students`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formattedStudent),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update student");
      }

      alert("Student updated successfully!");
      navigate("/students"); // Redirect back to student list
    } catch (error) {
      console.error("Error updating student:", error);
      alert("Failed to update student");
    }
  };

  if (loading) return <p className="text-center text-light">Loading...</p>;
  if (!student) return <p className="text-center text-danger">Student not found</p>;

  return (
    <div className="container mt-4">
      <Navbar groups={groups} />
      <div className="card p-4 shadow-lg">
        <h2 className="text-center text-primary">Edit Student</h2>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <label className="form-label">First Name:</label>
              <input
                type="text"
                name="first_name"
                value={student.first_name || ""}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Last Name:</label>
              <input
                type="text"
                name="last_name"
                value={student.last_name || ""}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <label className="form-label">Nickname:</label>
              <input
                type="text"
                name="nick_name"
                value={student.nick_name || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Date of Birth:</label>
              <input
                type="date"
                name="date_of_birth"
                value={student.date_of_birth || ""}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <label className="form-label">Phone Number:</label>
              <input
                type="text"
                name="phone"
                value={student.phone || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Email:</label>
              <input
                type="email"
                name="email"
                value={student.email || ""}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <label className="form-label">Current Rank:</label>
              <input
                type="text"
                name="current_rank"
                value={student.current_rank || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Current Stripes:</label>
              <input
                type="number"
                name="current_stripes"
                value={student.current_stripes || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <label className="form-label">Is Junior:</label>
              <select
                name="is_junior"
                value={student.is_junior ? "YES" : "NO"}
                onChange={(e) => setStudent({ ...student, is_junior: e.target.value === "YES" })}
                className="form-control"
              >
                <option value="YES">YES</option>
                <option value="NO">NO</option>
              </select>
            </div>
            <div className="col-md-6">
              <label className="form-label">Date of Last Promotion:</label>
              <input
                type="date"
                name="date_last_promoted"
                value={student.date_last_promoted || ""}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            {groups.includes("mtkd-admin") && (
                <div className="col-md-6">
                    <label className="form-label">Tuition Amount:</label>
                    <input
                    type="number"
                    name="tuition_amount"
                    value={student.tuition_amount || ""}
                    onChange={handleChange}
                    className="form-control"
                    />
                </div>
            )} 
          </div>

          <div className="d-flex justify-content-end mt-4">
            <button type="submit" className="btn btn-primary me-2">
                Save
            </button>
            <button type="button" className="btn btn-secondary" onClick={() => navigate("/students")}>
                Cancel
            </button>
            </div>
        </form>
      </div>
    </div>
  );
};

export default StudentDetail;
