import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import userPool from "./cognito";
import { jwtDecode } from "jwt-decode";

const AuthService = {
  login: (username, password) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (session) => {
          localStorage.setItem("token", session.getIdToken().getJwtToken());
          resolve(session);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  },

  logout: () => {
    localStorage.removeItem("token");
    window.location.reload();
  },

  getToken: () => localStorage.getItem("token"),

  isAuthenticated: () => {
    const token = localStorage.getItem("token");
    if (!token) return false;

    const decoded = jwtDecode(token);
    return decoded.exp * 1000 > Date.now();
  },
};

export default AuthService;
