import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { PencilSquare } from "react-bootstrap-icons"; // Bootstrap icon for edit

const StudentList = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState("");

  useEffect(() => {
    const fetchStudents = async () => {
      const token = localStorage.getItem("token"); // Retrieve token

      if (!token) {
        console.error("No authentication token found.");
        return;
      } else {
        const decoded = JSON.parse(atob(token.split(".")[1]));
        setGroups(decoded["cognito:groups"]);
      }

      try {
        const response = await fetch(
          "https://5l7ywi8ha8.execute-api.us-east-1.amazonaws.com/students",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch students");
        }
        const data = await response.json();
        console.log(data);
        setStudents(data);
      } catch (error) {
        console.error("Error fetching students:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, []);

  if (loading) return <p className="text-center text-light">Loading...</p>;

  return (
    <div className="container mt-4">
      <Navbar groups={groups} />
      <div className="card p-3 shadow-lg">
        <h2 className="text-center text-primary">Student List</h2>
        <div className="table-responsive">
          <table className="table table-dark table-hover table-bordered">
            <thead className="thead-light">
              <tr>
                <th>ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Nickname</th>
                <th>DOB</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Current Rank</th>
                <th>Current Stripes</th>
                <th>Is Junior</th>
                <th>Date of Last Promotion</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.student_id}>
                  <td>{student.student_id}</td>
                  <td>{student.first_name}</td>
                  <td>{student.last_name}</td>
                  <td>{student.nick_name}</td>
                  <td>{student.date_of_birth}</td>
                  <td>{student.phone}</td>
                  <td>{student.email}</td>
                  <td>{student.current_rank}</td>
                  <td>{student.current_stripes}</td>
                  <td>{student.is_junior ? "YES" : "NO"}</td>
                  <td>{student.date_last_promoted}</td>
                  <td className="text-center">
                    <Link to={`/students/edit/${student.student_id}`} className="text-warning">
                      <PencilSquare size={20} />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StudentList;
