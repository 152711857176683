import { Link } from 'react-router-dom'
import Logout from "./Logout";

const Navbar= ({ groups }) => {
    return(
        <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src="/mtkd-logo.png" alt="MTKD" width="120" height="32" class="d-inline-block align-text-top"/> 
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/students">Students</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/attendance">Attendance</Link>
              </li>
              {groups.includes("mtkd-admin") && (
              <li className="nav-item">
                <Link className="nav-link" to="/promotions">Promotions</Link>
              </li>
             )} 
            </ul>
            <ul className="navbar-nav ms-auto"> {/* Pushes this list to the right */}
              <li className="nav-item">
                <Logout />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
}

export default Navbar