import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import StudentList from "./components/StudentList";
import Attendance from "./components/Attendance";
import Promotions from "./components/Promotions";
import StudentDetail from "./components/StudentDetail";

import NavTest from "./components/NavTest"

import PrivateRoute from "./PrivateRoute";

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/students" element={<PrivateRoute><StudentList /></PrivateRoute>} />
          <Route path="/students/edit/:id" element={<PrivateRoute><StudentDetail /></PrivateRoute>} />
          <Route path="/attendance" element={<PrivateRoute><Attendance /></PrivateRoute>} />
          <Route path="/promotions" element={<PrivateRoute><Promotions /></PrivateRoute>} />
          <Route path="/nav-test" element={<NavTest />} />

        </Routes>
      </Router>
  );
}

export default App;
