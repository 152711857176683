import React, { useEffect, useState } from "react";
import Navbar from "./Navbar"

const NavTest = () => {

    return (
        <div>
            <Navbar />
        </div>
    )
}

export default NavTest