import { useEffect, useState } from "react";
import AuthService from "../auth/authService";
import Logout from "./Logout";
import Navbar from "./Navbar";


const Promotions = () => {
  const [username, setUsername] = useState("");
  const [groups, setGroups] = useState("");

  useEffect(() => {
    const token = AuthService.getToken();
    if (token) {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      setUsername(decoded["cognito:username"]);
      setGroups(decoded["cognito:groups"]);
    }
  }, []);

  return (
    <div>
      <Navbar groups={groups}/>
      <h2>Promotions</h2>
      <Logout />
    </div>
  );
};

export default Promotions;
