import { useEffect, useState } from "react";
import AuthService from "../auth/authService";
import Navbar from "./Navbar";

const Dashboard = () => {
  const [groups, setGroups] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const token = AuthService.getToken();
    if (token) {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      setGroups(decoded["cognito:groups"]);
      setEmail(decoded["email"]);
    }
  }, []);

  return (
    <div>
      <Navbar groups={groups} />
      <h2>Welcome, {email}</h2>
    </div>
  );
};

export default Dashboard;
